import React, { useEffect, useState, useContext } from "react";
import {api_url,api_assets_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Loansetting() {
  const {user } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [number, setnumber] = useState("");
  const [email, setemail] = useState("");
  const [Address, setAddress] = useState("");
  const [Adhar, setAdhar] = useState("");
  const [Pan, setPan] = useState("");
  const [bankname, setbankname] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [ifsccode, setifsccode] = useState("");
  const [upid, setupid] = useState("");
  const [image, setimage] = useState("");

  
  const [previewUrlRC, setPreviewUrlRC] = useState(null);  
  const onhandaltdocumentsubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("upid", upid);
    formdata.append("name", name);
    formdata.append("mobile", number);
    if(user.role != 1){
      formdata.append("email", email);
      formdata.append("address", Address);
      formdata.append("adhar", Adhar);
      formdata.append("pan", Pan);
    }
    formdata.append("bankname", bankname);
    formdata.append("accountnumber", accountnumber);
    formdata.append("ifsccode", ifsccode);
    formdata.append("image", image);
    fetch(api_url + "profileupdate", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          toast.success("update Successfully", {
            duration: 3000,
          });
          setLoading(false);
          fetchData();
        } else {
          toast.error("Insert failed");
        }
      })
      .catch((error) => {
        console.error("Insert error:", error);
      });
  };
 
  useEffect(() => {
    if (user.id > 0) {
      fetchData();
    }
  }, [user.id]);
  const fetchData = async () => {
    try {
      const response = await fetch(api_url + "profileview/" + user.id, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setupid(data.id)
      setname(data.name);
      setnumber(data.mobile);
      setemail(data.email);
      setAddress(data.address);
      setAdhar(data.adhar);
      setPan(data.pan);
      setbankname(data.bankname);
      setaccountnumber(data.accountnumber);
      setifsccode(data.ifsccode)
      setimage(data.image)
      setPreviewUrlRC(api_assets_url + 'upload/' + data.image)
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (image && image instanceof Blob) {
      try {
        const url = URL.createObjectURL(image);
        setPreviewUrlRC(url);
        return () => URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error creating object URL for Pancard:', error);
      }
    }


  }, [image]);
  const handleChange = (file) => {
    setimage(file[0]);
  };
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
                  
                    {/* <div className="d-flex align-items-center gap-2 gap-lg-3">										
                                    <a href="#" className="btn btn-sm fw-bold btn-secondary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Rollover</a>																				
                                    <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Target</a>										
                                </div>									 */}
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div class="container">
                      <div class="row">
                        <div class="col-md-3 col-xl-3 text-start">
                          <div class="text-center">
                          {image && previewUrlRC && (
                          <img
                            src={previewUrlRC}
                            alt="Preview RC"
                            className="mx-5 mt-2 avatar img-circle"
                            style={{ maxWidth: "50%", height: "106px" }}
                          />
                        )}

                                      
                            {/* <img
                              src="https://png.pngitem.com/pimgs/s/150-1503945_transparent-user-png-default-user-image-png-png.png"
                              class="avatar img-circle"
                              alt="avatar"
                            /> */}
                            <h6>Upload a different photo...</h6>

                            <input type="file" class="form-control"  onChange={(e) => handleChange(e.target.files)}/>
                          </div>
                        </div>

                        <div class="col-md-9 col-xl-9 personal-info">
                         

                          <form class="form-horizontal" role="form">
                          <h3 style={{marginTop:"10px",marginBottom:"10px"}}>
                            Personal info
                          </h3>
                            <div class="form-group style">
                              <label class="col-md-3 control-label">
                                Name:
                              </label>
                              <div class="col-md-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={name}
                                  onChange={(e) => {setname(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Mobile:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={number}
                                  onChange={(e) => {setnumber(e.target.value)}}
                                />
                              </div>
                            </div>
                            {user.id != 1&&(
                              <>
                              <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Email:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={email}
                                  onChange={(e) => {setemail(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Address:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={Address}
                                  onChange={(e) => {setAddress(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Adhar:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={Adhar}
                                  onChange={(e) => {setAdhar(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Pan Number:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={Pan}
                                  onChange={(e) => {setPan(e.target.value)}}
                                />
                              </div>
                            </div>
                            </>
                            )}
                            
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Bank Name:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={bankname}
                                  onChange={(e) => {setbankname(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                Account Number:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={accountnumber}
                                  onChange={(e) => {setaccountnumber(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                IFSC Code:
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="text"
                                  value={ifsccode}
                                  onChange={(e) => {setifsccode(e.target.value)}}
                                />
                              </div>
                            </div>


                           
                            <div class="form-group style">
                              <label class="col-md-3 control-label"></label>
                              <div class="col-md-8">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{
                                  background: isLoading ? "gray" : "primary",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandaltdocumentsubmit}
                              >
                                {isLoading ? 'Loading...' : 'Submit'}
                              </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Loansetting;
