import React,{useContext,useEffect,useState} from 'react';
import {AuthContext} from '../component/AuthProvider';
import {Link} from 'react-router-dom';
import {api_assets_url } from "../common/Helper";
import Skeleton from 'react-loading-skeleton';
const SidebarMenu = () => {
 const {user,islogin,login} = useContext(AuthContext);
 const [loading,setloading] = useState(true);
 var is_loading = true;
  useEffect(() => {
    const userdata = localStorage.getItem('userData');
    if(user){
        const data = JSON.parse(userdata)							
        login(data)   
		setloading(false)     
		
    }	
  }, [user.roleid]);

  return (
	<div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
		{loading ? (
			 <div className="skeleton-loading">
			 {/* Using react-loading-skeleton */}
			 <Skeleton count={4} height={20} />
		   </div>
		) : (

			<>
						<div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">							
							<Link to={"/dasboard"}>
								<img alt="Logo" src="assets/logo-white.png" className=" app-sidebar-logo-default" style={{width: '91px',height:'46px'}} />
								<img alt="Logo" src="assets/media/logos/custom-1.png" className="h-20px app-sidebar-logo-minimize" />
							</Link>																					
            
       
							<div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
								<i className="ki-duotone ki-black-left-line fs-3 rotate-180">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>
							</div>							
						</div>												
						<div className="app-sidebar-menu overflow-hidden flex-column-fluid">							
							<div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper">								
								<div id="kt_app_sidebar_menu_scroll" className="scroll-y my-5 mx-3" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">									
									<div className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">										
																													
										 <div className="menu-item">											
											<Link className="menu-link" to={"/dasboard"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Dashboard</span>
											</Link>											
										</div>
										
										{(user.roleid == 2 || user.roleid == 3) && (
											<div className="menu-item">											
											<Link className="menu-link" to={"/support"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Support</span>
											</Link>											
										</div>
										)}										
										<div className="menu-item">											
											<Link className="menu-link" to={"/supportlist"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Support List</span>
											</Link>											
										</div>										
										{user.roleid == 1 && (
										<div className="menu-item">											
											<Link className="menu-link" to={"/loan_setting"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Loan Setting</span>
											</Link>											
										</div>
										)}
										{user.roleid == 2 && (
										<div className="menu-item">											
											<Link className="menu-link" to={"/request"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Loan Request</span>
											</Link>											
										</div>
										)}
										{user.roleid == 1 && (
										<div className="menu-item">											
											<Link className="menu-link" to={"/loan_setting_list"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Loan List</span>
											</Link>											
										</div>
										)}
										<div className="menu-item">											
											<Link className="menu-link" to={"/requestlist"}>
												<span className="menu-icon">
													<i className="ki-duotone ki-calendar-8 fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
													</i>
												</span>
												<span className="menu-title">Request List</span>
											</Link>											
										</div>		
										{user.roleid == 1 && (
												<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-icon">
													<i class="ki-duotone ki-bank fs-2">
														<span class="path1"></span>
														<span class="path2"></span>
													</i>
													</span>
													<span class="menu-title">User List</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion menu-active-bg" style={{display:'none',overflow:'hidden'}} kt-hidden-height="83">
													<div class="menu-item">
													<Link class="menu-link" to={"/userslist/2"}>
														<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
														<span class="menu-title" style={{fontSize:'14px'}}>Loan Seeker</span>
													</Link>
													</div>
													<div class="menu-item">
													<Link class="menu-link"to={"/userslist/3"}>
														<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
														<span class="menu-title" style={{fontSize:'14px'}}>Investors</span>
													</Link>
													</div>
												</div>
												</div>
										)}	
									
									</div>									
								</div>								
							</div>							
						</div>	
						</>	
						)}			
																			
					</div>	
  );
};

export default SidebarMenu;