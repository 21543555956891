import React, { useState, useContext, useEffect } from "react";
import { useNavigate,Link } from "react-router-dom";
import { api_url } from "../common/Helper";
import { Check, ArrowRight, ArrowLeft } from "react-feather";
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
const Signup = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [image, setimage] = useState("");
  const [adhar, setadhar] = useState("");
  const [pan, setpan] = useState("");
  const [bankname, setbankname] = useState("");
  const [accountnumber, setaccountnumber] = useState("");
  const [ifsccode, setifsccode] = useState("");
  const [statementimage, setstatementimage] = useState("");
  const [role, setrole] = useState("");
  const [roleid, setroleid] = useState("");
  const [password, setpassword] = useState("");
  const [conpassword, setconpassword] = useState("");  
  const [isLoading, setLoading] = useState(false);
  const {login} = useContext(AuthContext);
  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };  
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleRCChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setimage(file);
      // setimageError("");
    }
  };
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleRCChangeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setstatementimage(file);
      // setstatementimageError("");
    }
  };

  const handleRoleIDNAME = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    const dataid = event.target.value;
    const label = event.target.options[selectedIndex].text;
    // const dataid = event.value;
    setrole(label);
    setroleid(dataid);
    // setroleError("");
  };
  const onhandalsubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setLoading(true)
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("email", email);
    formdata.append("address", address);
    formdata.append("image", image);
    formdata.append("adhar", adhar);
    formdata.append("pan", pan);
    formdata.append("bankname", bankname);
    formdata.append("accountnumber", accountnumber);
    formdata.append("ifsccode", ifsccode);
    formdata.append("statementimage", statementimage);
    formdata.append("role", role);
    formdata.append("roleid", roleid);
    formdata.append("password", password);    
    fetch(api_url + "registration", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(true)
        if(data.message == 1){
          toast.error("Email already exist");
        }else if(data.message == 2){
          toast.error("Phone already exist");
        }else{
          if (data) {
            
            toast.success("insert Successfully", {
              duration: 3000,
            });
            setTimeout(() => {
              navigate('/')
              }, 500);
            
          } else {
            toast.error("Insert failed");
          }
        }
      })
      .catch((error) => {
        toast.error("error: " + error.message);        
      });
  };
  const validateForm = () => {

    let isValid = true;

    if (name.trim() === "") {
      toast.error("Name is required");
      isValid = false;
      return
    }
    if (mobile.trim() === "") {
      
      toast.error("Mobile is required");
      isValid = false;
      return
    }
    if (email.trim() === "") {
      toast.error("Email is required");
      isValid = false;
      return
    }
    if (address.trim() === "") {
      toast.error("Address is required");
      isValid = false;
      return
    }
    if (image == "") {
      toast.error("Profile Image required");
      isValid = false;
      return
  }  
    if (adhar.trim() === "") {
      toast.error("Adhar is required");
      isValid = false;
      return
    }
    if (pan.trim() === "") {
      toast.error("PAN is required");
      isValid = false;
      return
    }
    if (bankname.trim() === "") {
      toast.error("Bank Name is required");
      isValid = false;
      return
    }
    if (accountnumber.trim() === "") {
      toast.error("Accont Number is required");
      isValid = false;
      return
    }
    if (ifsccode.trim() === "") {
      toast.error("IFSC Code is required");
      isValid = false;
      return
    }

    if (statementimage == '') {
      toast.error("Statement Image is required");
      isValid = false;
      return
  }
    if (role.trim() === "") {
      toast.error("Role is required");
      isValid = false;
      return
    }
    if (password.trim() === "") {
      toast.error("Password is required");
      isValid = false;
      return
    }

    if (conpassword.trim() === "") {
      toast.error("Confirm Password is required");
      isValid = false;
      return
    }
    if(password !== conpassword) {
      toast.error("Confirm password not match");
      isValid = false;
      return
    }
    if(!isValidEmail(email)) {
      toast.error("Emal is not valid");
      isValid = false;
      return
    }
    return isValid;
  };
  useEffect(() => {		
		const user = localStorage.getItem('userData');
		if(user){			
			const data = JSON.parse(user)							
			login(data)	
			navigate('/auth/dasboard');
		}		
		}, []);
  return (    
    <div className="d-flex flex-column flex-root" id="kt_app_root">
    <div
      className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
      id="kt_create_account_stepper"
    >
      <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
        <div
          className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center"
          style={{ backgroundImage: "url('assets/media/misc/auth-bg.png')" }}
        >
          <div className="d-flex flex-center py-lg-20 mt-lg-20">
            <a href="index.html">
            <img alt="Logo" src="assets/logo-white.png" class="h-60px h-lg-75px" />
            </a>
          </div>

          <div className="d-flex flex-row-fluid justify-content-center p-10">
            <div className="stepper-nav">
              <div
                className={`stepper-item ${
                  currentStep === 1
                    ? "current"
                    : currentStep > 1
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon rounded-3">
                    <Check className="ki-duotone ki-check fs-2 stepper-check" />
                    <span className="stepper-number">1</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title fs-2">Section One</h3>
                    {/* <div className="stepper-desc fw-normal">Select your account type</div> */}
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div
                className={`stepper-item ${
                  currentStep === 2
                    ? "current"
                    : currentStep > 2
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon rounded-3">
                    <Check className="ki-duotone ki-check fs-2 stepper-check" />
                    <span className="stepper-number">2</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title fs-2">Section Two</h3>
                    {/* <div className="stepper-desc fw-normal">Setup your account settings</div> */}
                  </div>
                </div>

                <div className="stepper-line h-40px"></div>
              </div>

              <div
                className={`stepper-item ${
                  currentStep === 3
                    ? "current"
                    : currentStep > 3
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <Check className="ki-duotone ki-check fs-2 stepper-check" />
                    <span className="stepper-number">3</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title fs-2">Section Three</h3>
                    {/* <div className="stepper-desc fw-normal">Setup your business details</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row-fluid">
        <div className="d-flex flex-center flex-column flex-column-fluid">
          <div className="w-lg-650px w-xl-700px p-lg-15">
            <form
              className="my-auto pb-5"
              novalidate="novalidate"
              id="kt_create_account_form"
              onSubmit={onhandalsubmit}
            >
              <div
                className={`${
                  currentStep === 1
                    ? "current"
                    : currentStep > 1
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="content"
              >
                <div className="w-100" style={{ marginTop: "50px" }}>
                  

                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>Name</label>

                    <input
                      type="text"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      
                      onChange={(e) => {
                        setname(e.target.value);
                        // setnameError("");
                      }}
                    />
                    {/* {nameError && (
                      <div className="text-danger">{nameError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      {" "}
                      Mobile number
                    </label>
                         <input
                          type="number"
                          name="mobile"
                          className="form-control form-control-lg form-control-solid"
                          value={mobile} 
                          onChange={(event) => {
                            const input = event.target.value;
                            const regex = /^[0-9]{0,10}$/;
                            if (regex.test(input)) {
                              setmobile(input);
                            }
                          }}
                        />

                    {/* {mobileError && (
                      <div className="text-danger">{mobileError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-0">
                    <label className="form-label required" style={{fontSize:'18px'}}>Email id</label>

                    <input
                      type="email"
                      name="business_email"
                      className="form-control form-control-lg form-control-solid "
                                                
                      onChange={(e) => {                            
                        setemail(e.target.value);                          
                      }}
                    />
                    {/* {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>Address</label>

                    <input
                      type="text"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setaddress(e.target.value);
                        // setaddressError("");
                      }}
                    />
                    {/* {addressError && (
                      <div className="text-danger">{addressError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}> image</label>

                    <input
                      type="file"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={handleRCChange}
                    />
                    {/* {imageError && (
                      <div className="text-danger">{imageError}</div>
                    )} */}
                  </div>
                </div>
              </div>

              <div
                className={`${
                  currentStep === 2
                    ? "current"
                    : currentStep > 2
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="content"
              >
                <div className="w-100" style={{ marginTop: "30px" }}>
                  <div className="pb-10 pb-lg-12">
                    <h2 className="fw-bold text-gray-900 text-center" style={{fontSize:'18px'}}>
                      Documents
                    </h2>
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>Aadhar</label>

                    <input
                      type="number"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      value={adhar}
                      onChange={(event) => {
                        const input = event.target.value;
                        const regex = /^[0-9]{0,12}$/;
                        if (regex.test(input)) {
                          setadhar(input);
                        }
                      }}
                     
                    />
                   
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>PAN</label>

                    <input
                      type="text"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setpan(e.target.value);
                        // setpanError("");
                      }}
                    />
                    {/* {panError && (
                      <div className="text-danger">{panError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-0">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      Bank Name
                    </label>

                    <input
                      type="text"
                      name="name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setbankname(e.target.value);
                        // setbankdError("");
                      }}
                    />
                    {/* {bankdError && (
                      <div className="text-danger">{bankdError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-0">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      Account Number
                    </label>

                    <input
                      type="number"
                      name="number"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setaccountnumber(e.target.value);
                        // setbankdError("");
                      }}
                    />
                    {/* {bankdError && (
                      <div className="text-danger">{bankdError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-0">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      IFSC Code
                    </label>

                    <input
                      type="text"
                      name="ifsc"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setifsccode(e.target.value);
                        // setbankdError("");
                      }}
                    />
                    {/* {bankdError && (
                      <div className="text-danger">{bankdError}</div>
                    )} */}
                  </div>
              
                  <div className="fv-row mb-0">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      {" "}
                      Statement Upload
                    </label>

                    <input
                      type="file"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={handleRCChangeUpload}
                    />
                    {/* {statementimageError && (
                      <div className="text-danger">
                        {statementimageError}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>

              <div
                className={`${
                  currentStep === 3
                    ? "current"
                    : currentStep > 3
                    ? "completed"
                    : ""
                }`}
                data-kt-stepper-element="content">
                <div className="w-100" style={{ marginTop: "30px" }}>
                  <div className="pb-10 pb-lg-12">
                    <h2 className="fw-bold text-gray-900">
                      Business Details
                    </h2>
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      Role selection{" "}
                    </label>

                    <select
                      name="business_type"
                      className="form-control form-control-lg form-control-solid"
                      data-control="select2"
                      onChange={handleRoleIDNAME}
                      data-placeholder="Select..."
                      data-allow-clear="true"
                      data-hide-search="true"
                    >
                      <option value="0">Selected Option</option>
                      <option value="2">Loan Seeker</option>
                      <option value="3">Investor</option>
                    </select>
                    {/* {roleError && (
                      <div className="text-danger">{roleError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>Password</label>

                    <input
                      type="password"
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setpassword(e.target.value);
                        // setpasswrdError("");
                      }}                          
                    />
                    {/* {passwrdError && (
                      <div className="text-danger">{passwrdError}</div>
                    )} */}
                  </div>
                  <div className="fv-row mb-10">
                    <label className="form-label required" style={{fontSize:'18px'}}>
                      Confirm Password
                    </label>

                    <input
                      name="business_name"
                      className="form-control form-control-lg form-control-solid"
                      onChange={(e) => {
                        setconpassword(e.target.value);
                        // setconpasswordError("");
                      }}                          
                    />
                    {/* {conpasswordError && (
                      <div className="text-danger">{conpasswordError}</div>
                    )} */}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack pt-15">
                {currentStep > 1 &&(
                     <button
                     type="button"
                     className="btn btn-lg btn-primary"
                     data-kt-stepper-action="next"
                     onClick={handlePrevious}
                   >
                     {" "}
                     <ArrowLeft size={15} className="me-2" />
                     Previous
                   </button>
                   
                )}
                

                <div>
                  {currentStep === 3 ? (
                    <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    style={{
                      background: isLoading ? "gray" : "primary",
                      border: "1px solid #FFFFFF",
                      cursor: isLoading ? "gray" : "aquamarine",
                    }}
                    onClick={onhandalsubmit}
                  >
                    {isLoading ? 'Loading...' : 'Submit'}
                    <ArrowRight size={15} className="ms-3" />
                  </button>
                    // <button
                    //   type="button"
                    //   className="btn btn-lg btn-primary"
                    //   data-kt-stepper-action="next"
                    //   onClick={onhandalsubmit}
                    // >
                    //   Submit
                    //   <ArrowRight size={15} className="ms-3" />
                    // </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      
                      onClick={handleNext}
                    >
                      Continue
                      <ArrowRight size={15} className="ms-3" />
                    </button>
                  )}
                </div>
             
              </div>
              <div className="text-gray-500 text-center mt-5" style={{fontSize:'18px'}}>Already have an Account? 
            <Link to={"/"} className="link-primary" style={{fontWeight:'700'}}> Sign in</Link></div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer/>
  </div>
  );
};

export default Signup;
