import React, { useEffect,useState ,useContext} from "react";
import {api_url,numberToWords } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';

function Loansetting() {
  
  const navigate = useNavigate();
    const [hour, sethour] = useState("");
    const [amountinword, setamountinword] = useState('');
    const [hourid, sethourid] = useState("");
    const [amount, setamount] = useState("");
    const [hourError, sethourError] = useState('');
    const [amountError, setamountError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const {user} = useContext(AuthContext); 
    const [loansettingoptions, setloansettingoptions] = useState([{ value: "", label: "" }]);
    const onhandaltdocumentsubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
          return;
        }
        setLoading(true);
        const formdata = new FormData();
        formdata.append("roletype", user.roleid);
        formdata.append("userid", user.id);
        formdata.append("hour", hour);
        formdata.append("hourid", hourid);
        formdata.append("amount", amount);
        fetch(api_url + "addrequest", {
          method: "POST",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              toast.success("insert Successfully", {
                duration: 3000, 
              });
              setLoading(false);
              navigate('/requestlist')
            } else {
              toast.error("Insert failed");
            }
          })
          .catch((error) => {
            console.error("Insert error:", error);
          });
      };
      const validateForm = () => {
        sethourError("");
        setamountError("");
       
        let isValid = true;
       
       
        //batch id
        if (amount.trim() === "") {
          setamountError("Amount is required");
          isValid = false;
        }
        if (hour.trim() === "") {
          sethourError("Hour is required");
          isValid = false;
        }
        return isValid;
       };
      useEffect(() => {
        fetchcategory();
      }, []);
      const fetchcategory = () => {
        fetch(api_url + "getloansetting")
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            let arrissue = [];
            arrissue.push({ value: 0, label: "Select Hour" });
            if (data.length > 0) {
              for (let x in data) {
                let obj = {
                  value: data[x].id,
                  label: data[x].hour +' Hrs',
                };
                arrissue.push(obj);
              }
            }
            setloansettingoptions(arrissue);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        };
        const handleloansettingoptions = (selectedOption) => {
          sethour(selectedOption.label);
          sethourid(selectedOption.value);
          sethourError('');
        };
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
                    
                  
                  </div>
                </div>
            
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                        <div className="d-flex flex-column flex-column-fluid">
                <img src="assets/request-loan-amount.png" alt="" style={{width:'100%',borderRadius:'3%',marginTop:'20px'}} />
                  </div>
                    <div className="row gx-10 gx-xl-10">
                      <div className="col-xxl-12 mb-5 mb-xl-12">
                        <div className=" card-flush h-xl-100">
                        <div className="row">
                          <div className="col-xxl-1 mb-5 mb-xl-1">
                            </div>
                            <div className="col-xxl-10 mb-5 mb-xl-10">
                            <form onSubmit={onhandaltdocumentsubmit}>
                            <div className="mt-5">
                              <label style={{padding:"0px 15px"}} className="form-label required">
                              Amount 
                              </label>
                              <input
                                type="number" 
                                onChange={(e) => {
                                  setamount(e.target.value);
                                  setamountError('');
                                  setamountinword(numberToWords(e.target.value))
                                  
                                }}
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter Loan Amount"
                              />
                              {amountinword && <div className="text-info" style={{marginLeft:'10px'}}>{amountinword}</div>}
                              <div style={{marginBottom:'10px'}}></div>
                              {amountError && <div className="text-danger">{amountError}</div>}
                            </div>
                            <div className="mt-4">
                            <label style={{padding:"0px 15px"}} className="form-label required">Hour</label>
                            <Select
                              id="status"
                              placeholder="Select Hour"
                              isClearable={false}
                              className={`react-select  form-select-solid`}
                              classNamePrefix="select"
                              options={loansettingoptions}
                              // value={groupoptionsdata.find(
                              //   (option) => option.value === courseGroupId1
                              // )}
                              onChange={handleloansettingoptions}  
                            />
                            {hourError && <div className="text-danger">{hourError}</div>}
                            </div>
                            
                            <div className="mt-5" style={{textAlign:'center'}}>
                              
                            <button
  type="submit"
  className="ntnsubmit"
  style={{
    width: '50%',
    padding: '6px',
    color: '#FFFFFF',
    fontWeight: '700',
    background: isLoading ? "gray" : "#232323",
    border: "1px solid #FFFFFF",
    cursor: isLoading ? "gray" : "aquamarine",
    fontSize: '15px' // Set the font size to 15px
  }}
  onClick={onhandaltdocumentsubmit}
>
  {isLoading ? 'Loading...' : 'Submit'} {/* Remove the span */}
</button>

                            </div>
                          </form>
                              </div>
                              </div>

                       
                        </div>
                      </div>
                      <div className="col-xl-6 mb-5 mb-xl-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}
export default Loansetting;
