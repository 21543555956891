import React, { useEffect,useContext,useState} from "react"; // Import useEffect from React
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from '../component/AuthProvider';

function Dashboard() {
  const {user} = useContext(AuthContext);
  const [totalloan, settotalloan] = useState('');
  const [pendingloan, setpendingloan] = useState('');
  const [paidloan, setpaidloan] = useState('');
  const [myscore, setmyscore] = useState('');
  const [totalinvestment, settotalinvestment] = useState('');
  const [totalwith, settotalwith] = useState('');
  const [currentinvestment, setcurrentinvestment] = useState('');

  const fetchDashBoardData = async () => {    
    try {      
      const response = await fetch(api_url + 'dashboarddata/'+ user.roleid+'/'+user.id, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
     if(user.roleid == 2){
        settotalloan(data.data.totalloan);
        setpendingloan(data.data.pendingloan);
        setpaidloan(data.data.totalpaid);
        setmyscore(data.data.score);
      
     }
     if(user.roleid == 3){
      settotalinvestment(data.data.totalinvestment);
      settotalwith(data.data.totalwith);
      setcurrentinvestment(data.data.currentinvestment);
     }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
  }; 
  useEffect(() => {
    // Assuming `KTComponents` is the global variable that initializes your components
    if (window.KTComponents) {
      window.KTComponents.init();
    }    
    if(user.id > 0){
       fetchDashBoardData();
    }
  }, [user.id]);
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
              
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
{user.roleid != 1&&(
 <div className="d-flex flex-column flex-column-fluid">
 <img src="assets/homepage.png" alt="" style={{width:'100%',borderRadius:'3%',marginTop:'20px'}} />
   </div>
)}
                   
                   {user.roleid == 1&&(
 <div className="d-flex flex-column flex-column-fluid">
 <img src="assets/admin.png" alt="" style={{width:'100%',borderRadius:'3%',marginTop:'20px'}} />
   </div>
)}


{user.roleid==2 ? (

    <div className="row g-5 g-xl-10 mb-xl-10" style={{marginTop:'20px'}}>
      <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
              
              {totalloan} 

            </span>

            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            Total Loan 
            </span>
          </div>
        </div>
        </div>
        </div>  
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
            {pendingloan}

            </span>

            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
              Pending Loan 
            </span>
          </div>
        </div>
        </div>
        </div> 
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
              {paidloan} 

            </span>

            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            Total Paid 
            </span>
          </div>
        </div>
        </div>
        </div>  
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
              {myscore} 
            </span>

            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            My Score
            </span>
          </div>
        </div>
        </div>
        </div> 
    </div>
) : (
  <div className="row g-5 g-xl-10 mb-xl-10" style={{marginTop:'20px'}}>
     <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
             
              {totalinvestment}

            </span>
            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            Total investment
            </span>
          </div>
        </div>
        </div>
        </div>
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
            
{currentinvestment}
            </span>
            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            Current invesment
            </span>
          </div>
        </div>
        </div>
        </div>
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
            {totalwith}
            </span>
            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            Withdrawals
            </span>
          </div>
        </div>
        </div>
        
        </div>
        <div className="col-6">
      <div
        className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
              0
            </span>
            <span className="text-gray-900 opacity-50 pt-1 fw-semibold fs-6">
            ROI
            </span>
          </div>
        </div>
        </div>
        </div>
  </div>
)}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
