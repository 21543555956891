import React, { useEffect, useState } from "react";
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import Select from "react-select";
function Loansettinglist() {
  const [loansettinglist, setloansettinglist] = useState([]);

  
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(api_url + 'getloansetting', {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setloansettinglist(data); 
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <div
        classNameName="d-flex flex-column flex-root app-root"
        id="kt_app_root"
      >
        <div
          classNameName="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            classNameName="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              classNameName="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div classNameName="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  classNameName="app-toolbar py-3 py-lg-6"
                >
                  <div
                    id="kt_app_toolbar_container"
                    classNameName="app-container container-fluid d-flex flex-stack"
                  >
                   
                   							
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  classNameName="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    classNameName="app-container container-fluid"
                  >
                    <div
                      className="row gy-5 g-xl-10"
                      data-select2-id="select2-data-126-jy4v"
                    >
                      <div className="col-xl-3 mb-xl-10">
                        
                      </div>

                      <div
                        className="col-xl-8  mb-xl-10"
                        data-select2-id="select2-data-125-pj7l"
                      >
                        <div
                          className="card card-flush h-xl-100"
                          data-select2-id="select2-data-124-kptd"
                        >
                          <div
                            className="card-header pt-7"
                            data-select2-id="select2-data-123-37nk"
                          >
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Product Orders
                              </span>
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                Avg. 57 orders per day
                              </span>
                            </h3>

                            <div className="card-toolbar">
                              <div className="d-flex flex-stack flex-wrap gap-4">
                                <div className="position-relative my-1">
                                  <i className="ki-duotone ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <input
                                    type="text"
                                    data-kt-table-widget-4="search"
                                    className="form-control w-150px fs-7 ps-12"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body pt-2">
                            <div
                              id="kt_table_widget_4_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_4_table"
                                >
                                  <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        ID
                                      </th>
                                      <th
                                        className="text-end min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        Hour
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        Intresat
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        Aciton
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="fw-bold text-gray-600">
                                  {loansettinglist.map((item, index) => (
                                    <tr className="odd" key={index}>
                                      <td>
                                       
                                         {item.id}
                                      
                                      </td>
                                      <td className="text-end">{item.hour}</td>
                                      <td className="text-end">
                                       
                                         {item.intresat}
                                      
                                      </td>
                                     
                                      <td className="text-end">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                          data-kt-table-widget-4="expand_row"
                                        >
                                         <i className="ki-duotone ki-pencil">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        </i>
                                          {/* <i className="ki-duotone ki-minus fs-4 m-0 toggle-on"></i> */}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}  
                                  </tbody>
                                
                                </table>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-1 mb-xl-10">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loansettinglist;
