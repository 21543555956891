import React, { useEffect ,useContext} from "react"; // Import useEffect from React
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import { Link } from "react-router-dom";
function Mobilemenu() {
    const {user} = useContext(AuthContext); 
  useEffect(() => {
    // Assuming `KTComponents` is the global variable that initializes your components
    if (window.KTComponents) {
      window.KTComponents.init();
    }
  }, []);
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            <Header />
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              <SidebarMenu />
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-3">
                    <div
                      id="kt_app_toolbar_container"
                      className="app-container container-fluid d-flex flex-stack"
                    >
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3"></div>
                    </div>
                  </div>
                  <div
                    id="kt_app_content"
                    className="app-content flex-column-fluid"
                  >
                    <div
                      id="kt_app_content_container"
                      className="app-container container-fluid"
                      style={{ minHeight: "100vh" }}
                    >
                       <div className="row">
            <div className="container">
            <div className="row">
    <div className="col-lg-4 col-md-6 col-6">
    
      <Link to={"/dasboard"} className="text-decoration-none">
        <div className="card mb-4 ">
          <div className="card-body text-center">
          <i class="ki-duotone ki-home-1" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
</i>
            <h5 className="card-title">Dashboard</h5>
          </div>
        </div>
      </Link>
       
    </div>
    {(user.roleid == 2 || user.roleid == 3) &&(
      <div className="col-lg-4 col-md-6 col-6">
        <Link to={"/support"} className="card-link text-decoration-none">
          <div className="card mb-3">
            <div className="card-body text-center">
              <i className="fas fa-user-plus mb-2" style={{ fontSize: '2rem',color:'#99a1b7'}}></i>
              <h5 className="card-title">Support</h5>
            </div>
          </div>
        </Link>
      </div>
    )}
   
    <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/supportlist"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-questionnaire-tablet" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
</i>
            <h5 className="card-title">Support List</h5>
          </div>
        </div>
      </Link>
    </div>
    {user.roleid == 1 && (
    <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/loan_setting"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-gear" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
</i>
            <h5 className="card-title">Loan Setting</h5>
          </div>
        </div>
      </Link>
    </div>
    )}
    {user.roleid == 2 && (
    <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/request"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-entrance-left" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
</i>
            <h5 className="card-title">Loan Request</h5>
          </div>
        </div>
      </Link>
    </div>
    )}
    {user.roleid == 1 && (
    <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/loan_setting_list"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-questionnaire-tablet" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
</i>
            <h5 className="card-title">Loan List</h5>
          </div>
        </div>
      </Link>
    </div>
    )}

    {user.roleid != 3 &&(
       <div className="col-lg-4 col-md-6 col-6">
       <Link to={"/requestlist"} className="card-link text-decoration-none">
         <div className="card mb-3">
           <div className="card-body text-center">
           <i class="ki-duotone ki-tablet-down" style={{fontSize:'30px'}}>
  <span class="path1"></span>
  <span class="path2"></span>
  <span class="path3"></span>
 </i>
             <h5 className="card-title">
               {user.roleid == 1 ? "Loan Request List" : ' My Loans'}
 
             </h5>
           </div>
         </div>
       </Link>
     </div>
    )}
    {user.roleid != 2 &&(
       <div className="col-lg-4 col-md-6 col-6">
       <Link to={"/myinvestment"} className="card-link text-decoration-none">
         <div className="card mb-3">
           <div className="card-body text-center">
           <i class="ki-duotone ki-tablet-down" style={{fontSize:'30px'}}>
  <span class="path1"></span>
  <span class="path2"></span>
  <span class="path3"></span>
 </i>
             <h5 className="card-title">
               {user.roleid == 1 ? "Investment List" : ' My Investment'}
 
             </h5>
           </div>
         </div>
       </Link>
     </div>
    )}
   
    {user.roleid == 1 && (
    <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/userslist/2"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-speaker" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>
            <h5 className="card-title">loan seeker</h5>
          </div>
        </div>
      </Link>
    </div>
    )}
       {user.roleid == 1 && (
     <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/userslist/3"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-delivery-3" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>
            <h5 className="card-title">Investor</h5>
          </div>
        </div>
      </Link>
    </div>
)}
  {user.roleid == 3 && (
     <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/investment"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-delivery-3" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>
            <h5 className="card-title">Invesment</h5>
          </div>
        </div>
      </Link>
    </div>
)}
  {user.roleid == 3 && (
     <div className="col-lg-4 col-md-6 col-6">
      <Link to={"/withdrawal"} className="card-link text-decoration-none">
        <div className="card mb-3">
          <div className="card-body text-center">
          <i class="ki-duotone ki-wallet" style={{fontSize:'30px'}}>
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>
            <h5 className="card-title">withdrawal</h5>
          </div>
        </div>
      </Link>
    </div>
)}
 
      

  </div>
</div>
</div>


                    </div>
                  </div>
                </div>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Mobilemenu;
