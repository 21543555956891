import React, { useEffect, useState,useContext } from "react";
import {api_url,api_assets_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import {useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Label,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
function Supportlist() {
  const {user} = useContext(AuthContext); 
  const [getsupportlist, setgetsupportlist] = useState([]);
  const [getsupport, setgetsupport] = useState([]);  
  const [Reply, setReply] = useState(false);
  const [viewsupport, setviewsupport] = useState(false);
  const [MessageReplyid, setMessageReply] = useState([]);
  const [message, setmessage] = useState("");
  const [view_data, setview_data] = useState('');

  useEffect(() => {
    if(user.id > 0){
      fetchData();
    }
    
  }, [user.id]);
  const fetchData = async () => {    
    try {
      const response = await fetch(api_url + 'getsupport/'+ user.roleid+'/'+user.id, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setgetsupportlist(data);       
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const roptions = [
    { value: '0', label: 'panding' },
    { value: '1', label: 'complete' },
  ];
  const handlelsuppoetoptions = (selectedOption) => {
    console.log(selectedOption.label);
    console.log(selectedOption.value);
  };
  
  function setclose(id) {
    fetch(api_url + 'supportclose/' + id, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        toast.success("Close Successfully");
        fetchData();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  // function setclose(id) {
  //   fetch(api_url + 'supportclose/' + id, {
  //     method: 'GET'
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       toast.success("Insert failed");
  //       fetchData();
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }
  const replymessage = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("userid", MessageReplyid);
    formdata.append("message", message);
    fetch(api_url + "addreplymessage", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {

          toast.success("insert Successfully", {
            duration: 3000, 
          });
          setReply(false)
          fetchData();
        } else {
          toast.error("Insert failed");
        }
      })
      .catch((error) => {
        toast.error("Insert error: " + error.message);
        console.error("Insert error:", error);
      });
  };
  
  // const navigate = useNavigate()
  const setsupportview = (id) =>{
    fetch(api_url + 'viewpage/' + id, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        setview_data(data)
        fetchData();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
   
   
  }
  return (
    <>
      <div
        classNameName="d-flex flex-column flex-root app-root"
        id="kt_app_root"
      >
        <div
          classNameName="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            classNameName="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              classNameName="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div classNameName="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  classNameName="app-toolbar py-3 py-lg-6"
                >
                  <div
                    id="kt_app_toolbar_container"
                    classNameName="app-container container-fluid d-flex flex-stack"
                  >
                
                   							
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  classNameName="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    classNameName="app-container container-fluid"
                  >
                    <div
                      className="row gy-5 g-xl-10"
                      data-select2-id="select2-data-126-jy4v"
                    >
                      <div className="col-xl-3 mb-xl-10">
                        
                      </div>

                      <div
                        className="col-xl-8  mb-xl-10"
                        data-select2-id="select2-data-125-pj7l"
                      >
                        <div
                          className="card card-flush h-xl-100"
                          data-select2-id="select2-data-124-kptd"
                        >
                          <div
                            className="card-header pt-7"
                            data-select2-id="select2-data-123-37nk"
                          >
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Support List
                              </span>
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">
                               
                              </span>
                            </h3>

                            <div className="card-toolbar">
                              <div className="d-flex flex-stack flex-wrap gap-4">
                                <div className="position-relative my-1">
                                  <i className="ki-duotone ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <input
                                    type="text"
                                    data-kt-table-widget-4="search"
                                    className="form-control w-150px fs-7 ps-12"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body pt-2">
                            <div
                              id="kt_table_widget_4_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_4_table"
                                >
                                  <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        ID
                                      </th>
                                      <th
                                        className="text-end min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        User Name
                                      </th>
                                      <th
                                        className="text-end min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        title
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        subject
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        documentimage
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        status
                                      </th>
                                      
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        Aciton
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="fw-bold text-gray-600">
                                  {getsupportlist.map((item, index) => (
                                    
                                    <tr className="odd" key={index}>
                                      <td>
                                        
                                         {item.id}
                                    
                                      </td>
                                    
                                        <div>
                                        <td className="text-end">{item.name}</td>
                                        </div>
                                      <td className="text-end">{item.title}</td>
                                      <td className="text-end">
                                        
                                         {item.subject}
                                    
                                      </td>
                                      <td className="text-end">
                                        
                                          <img
                                            src={api_assets_url + "upload/" + item.documentimage}
                                            alt="img-fluid"
                                            style={{ width: "100px", height: "100px" }} 
                                          />
                                  
                                      </td>

                                      
                                        <td className="text-end">
                                            
                                                <span style={{padding:'10px'}} className={`badge ${item.status == 0 ? 'badge-light-warning' : item.status == 1 ? 'badge-light-primary' : 'badge-light-danger'}`}>
                                                {item.status == 0 ? 'padding' : item.status == 1 ? 'compale' : 'cancal'}
                                                </span>
                                          
                                            </td>
                                           
                                            
                                      <td className="text-end">
                                      <button
                                          type="button"
                                   
                                          onClick={() => { setviewsupport(true); setsupportview(item.id); }}
                                          className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px me-2"
                                          data-kt-table-widget-4="expand_row"
                                        >
                                        <i className="ki-duotone ki-eye">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        </i>
                                        </button>
                                        {user.roleid == 1 && (
                                        <button id="openModal" onClick={() => { setReply(true); setMessageReply(item.id); }}>Reply</button>
                                        )}
                                        </td>
                                      {user.roleid == 1 && (
                                      <td className="text-end">
                                      
                                        <button onClick={() => setclose(item.id)}>close</button>
                                    
                                        </td>
                                      )}
                                 
                                    </tr>
                                  ))}
                                   <Modal
                                          isOpen={Reply}
                                          toggle={() => setReply(!Reply)}
                                          className="modal-dialog-centered modal-lg"
                                        >
                                          <ModalHeader
                                            className="bg-transparent"
                                            toggle={() => setReply(!Reply)}
                                          ></ModalHeader>
                                          <form enctype="multipart/form-data" onSubmit={replymessage}>
                                            <ModalBody
                                              className="px-sm-5 mx-50 pb-5"
                                              style={{ maxHeight: "400px", overflowY: "scroll" }}
                                            >
                                              <div className="text-center mb-2">
                                                <h1 className="mb-1">
                                                Reply
                                                </h1>
                                                
                                              </div>
                                              <div >
                                              <label for="exampleFormControlTextarea1" className="form-label">Message</label>
                                              <textarea className="form-control" id="exampleFormControlTextarea1"   onChange={(e) => {setmessage(e.target.value)}} rows="3"/>
                                              </div>
                                          
                                            </ModalBody>
                                            <div className="row" >
                                              <div className="col-md-12 text-center">
                                                <button
                                                  type="submit"
                                                  className="mt-3"
                                                  style={{ margin: "40px" }}
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </Modal>
  
    

                                        <Modal
                                          isOpen={viewsupport}
                                          toggle={() => setviewsupport(!viewsupport)}
                                          className="modal-dialog-centered modal-lg"
                                        >
                                          
                                          <ModalHeader
                                          
                                            className="bg-transparent"
                                            toggle={() => setviewsupport(!viewsupport)}
                                          ><h5 className="mb-1 text-center">
                                          View's
                                          </h5></ModalHeader>
                                        
                                            <ModalBody
                                              className="px-sm-5 mx-50 pb-5"
                                              style={{ maxHeight: "400px", overflowY: "scroll" }}
                                            >
                                              {view_data && (
                                              <div className="text-center mb-2">
                                                
                                                <h4>{ view_data.message}</h4>
                                                <p>Date: {view_data.rdate}</p> 
                                             <p>Time: {view_data.rtime}</p> 
                                              </div>
                                           
                                              
                                          )}
                                            </ModalBody>
                                           
                                   
                                        </Modal>  
                                  </tbody>
                                
                                </table>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-1 mb-xl-10">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Supportlist;
