import React, { useEffect, useState,useContext } from "react";
import {Link,useNavigate} from 'react-router-dom';
import { api_url,api_assets_url } from "../common/Helper";
import {AuthContext} from '../component/AuthProvider';
import $ from "jquery";

const Header = () => {
    const {user,logout} = useContext(AuthContext); 
    const navigate = useNavigate();
	useEffect(() => {
		// Assuming `KTComponents` is the global variable that initializes your components
		if (window.KTComponents) {
		  window.KTComponents.init();
		}
		console.log(user.image);
	  }, []);    
    const showusermenu = () => {
        $('#usermenu').toggleClass('show');
      }
      const handleSignOut = () => {        
        logout();   
        navigate('/');  
      };
  return (
    <div id="kt_app_header" className="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: true, lg: true}" data-kt-sticky-name="app-header-minimize" data-kt-sticky-offset="{default: '200px', lg: '0'}" data-kt-sticky-animation="false">
				
    <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
        <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
        <Link to={"/mobilemenu"}>
            <div className="btn btn-icon btn-active-color-primary w-35px h-35px" >
               
                <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                </i>
                
                
            </div>
            </Link>
        </div>
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to={"/dasboard"} className="d-lg-none">
                <img alt="Logo" src="assets/logo-black.png" className="h-30px" />
            </Link>
        </div>
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
            <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
                <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu" data-kt-menu="true">
                   									
                </div>								
            </div>														
            <div className="app-navbar flex-shrink-0">								
                <div className="app-navbar-item align-items-stretch ms-1 ms-md-4">									
                    <div id="kt_header_search" className="header-search d-flex align-items-stretch" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-menu-trigger="auto" data-kt-menu-overflow="false" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">										
                        <div className="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
                            
                        </div>																				
                        <div data-kt-search-element="content" className="menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px">											
                            <div data-kt-search-element="wrapper">												
                                <form data-kt-search-element="form" className="w-100 position-relative mb-3" autocomplete="off">													
                                    <i className="ki-duotone ki-magnifier fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-0">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>																										
                                    <input type="text" className="search-input form-control form-control-flush ps-10" name="search" value="" placeholder="Search..." data-kt-search-element="input" />																										
                                    <span className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1" data-kt-search-element="spinner">
                                        <span className="spinner-border h-15px w-15px align-middle text-gray-500"></span>
                                    </span>																										
                                    <span className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none" data-kt-search-element="clear">
                                        <i className="ki-duotone ki-cross fs-2 fs-lg-1 me-0">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                        </i>
                                    </span>																										
                                    <div className="position-absolute top-50 end-0 translate-middle-y" data-kt-search-element="toolbar">														
                                        <div data-kt-search-element="preferences-show" className="btn btn-icon w-20px btn-sm btn-active-color-primary me-1" data-bs-toggle="tooltip" title="Show search preferences">
                                            <i className="ki-duotone ki-setting-2 fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                        </div>																												
                                        <div data-kt-search-element="advanced-options-form-show" className="btn btn-icon w-20px btn-sm btn-active-color-primary" data-bs-toggle="tooltip" title="Show more search options">
                                            <i className="ki-duotone ki-down fs-2"></i>
                                        </div>														
                                    </div>													
                                </form>																								
                                <div className="separator border-gray-200 mb-6"></div>																								
                                <div data-kt-search-element="results" className="d-none">													
                                    <div className="scroll-y mh-200px mh-lg-350px">														
                                        <h3 className="fs-5 text-muted m-0 pb-5" data-kt-search-element="category-title">Users</h3>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <img src="assets/media/avatars/300-6.jpg" alt="" />
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Karina Clark</span>
                                                <span className="fs-7 fw-semibold text-muted">Marketing Manager</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <img src="assets/media/avatars/300-2.jpg" alt="" />
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Olivia Bold</span>
                                                <span className="fs-7 fw-semibold text-muted">Software Engineer</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <img src="assets/media/avatars/300-9.jpg" alt="" />
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Ana Clark</span>
                                                <span className="fs-7 fw-semibold text-muted">UI/UX Designer</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <img src="assets/media/avatars/300-14.jpg" alt="" />
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Nick Pitola</span>
                                                <span className="fs-7 fw-semibold text-muted">Art Director</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <img src="assets/media/avatars/300-11.jpg" alt="" />
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Edward Kulnic</span>
                                                <span className="fs-7 fw-semibold text-muted">System Administrator</span>
                                            </div>															
                                        </a>																												
                                        <h3 className="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Customers</h3>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <img className="w-20px h-20px" src="assets/media/svg/brand-logos/volicity-9.svg" alt="" />
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Company Rbranding</span>
                                                <span className="fs-7 fw-semibold text-muted">UI Design</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <img className="w-20px h-20px" src="assets/media/svg/brand-logos/tvit.svg" alt="" />
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Company Re-branding</span>
                                                <span className="fs-7 fw-semibold text-muted">Web Development</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <img className="w-20px h-20px" src="assets/media/svg/misc/infography.svg" alt="" />
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Business Analytics App</span>
                                                <span className="fs-7 fw-semibold text-muted">Administration</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <img className="w-20px h-20px" src="assets/media/svg/brand-logos/leaf.svg" alt="" />
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">EcoLeaf App Launch</span>
                                                <span className="fs-7 fw-semibold text-muted">Marketing</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <img className="w-20px h-20px" src="assets/media/svg/brand-logos/tower.svg" alt="" />
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column justify-content-start fw-semibold">
                                                <span className="fs-6 fw-semibold">Tower Group Website</span>
                                                <span className="fs-7 fw-semibold text-muted">Google Adwords</span>
                                            </div>															
                                        </a>																												
                                        <h3 className="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Projects</h3>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-notepad fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                        <span className="path4"></span>
                                                        <span className="path5"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <span className="fs-6 fw-semibold">Si-Fi Project by AU Themes</span>
                                                <span className="fs-7 fw-semibold text-muted">#45670</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-frame fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                        <span className="path4"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <span className="fs-6 fw-semibold">Shopix Mobile App Planning</span>
                                                <span className="fs-7 fw-semibold text-muted">#45690</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-message-text-2 fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <span className="fs-6 fw-semibold">Finance Monitoring SAAS Discussion</span>
                                                <span className="fs-7 fw-semibold text-muted">#21090</span>
                                            </div>															
                                        </a>																												
                                        <a href="#" className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-profile-circle fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <span className="fs-6 fw-semibold">Dashboard Analitics Launch</span>
                                                <span className="fs-7 fw-semibold text-muted">#34560</span>
                                            </div>															
                                        </a>														
                                    </div>													
                                </div>																								
                                <div className="mb-5" data-kt-search-element="main">													
                                    <div className="d-flex flex-stack fw-semibold mb-4">														
                                        <span className="text-muted fs-6 me-2">Recently Searched:</span>														
                                    </div>																										
                                    <div className="scroll-y mh-200px mh-lg-325px">														
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-laptop fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">BoomApp by Keenthemes</a>
                                                <span className="fs-7 text-muted fw-semibold">#45789</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-chart-simple fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                        <span className="path4"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">"Kept API Project Meeting</a>
                                                <span className="fs-7 text-muted fw-semibold">#84050</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-chart fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">"KPI Monitoring App Launch</a>
                                                <span className="fs-7 text-muted fw-semibold">#84250</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-chart-line-down fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">Project Reference FAQ</a>
                                                <span className="fs-7 text-muted fw-semibold">#67945</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-sms fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">"FitPro App Development</a>
                                                <span className="fs-7 text-muted fw-semibold">#84250</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-bank fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">Shopix Mobile App</a>
                                                <span className="fs-7 text-muted fw-semibold">#45690</span>
                                            </div>															
                                        </div>																												
                                        <div className="d-flex align-items-center mb-5">															
                                            <div className="symbol symbol-40px me-4">
                                                <span className="symbol-label bg-light">
                                                    <i className="ki-duotone ki-chart-line-down fs-2 text-primary">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                </span>
                                            </div>																														
                                            <div className="d-flex flex-column">
                                                <a href="#" className="fs-6 text-gray-800 text-hover-primary fw-semibold">"Landing UI Design" Launch</a>
                                                <span className="fs-7 text-muted fw-semibold">#24005</span>
                                            </div>															
                                        </div>														
                                    </div>													
                                </div>																								
                                <div data-kt-search-element="empty" className="text-center d-none">													
                                    <div className="pt-10 pb-10">
                                        <i className="ki-duotone ki-search-list fs-4x opacity-50">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                        </i>
                                    </div>																										
                                    <div className="pb-15 fw-semibold">
                                        <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
                                        <div className="text-muted fs-7">Please try again with a different query</div>
                                    </div>													
                                </div>												
                            </div>																						
                            <form data-kt-search-element="advanced-options-form" className="pt-1 d-none">												
                                <h3 className="fw-semibold text-gray-900 mb-7">Advanced Search</h3>																								
                                <div className="mb-5">
                                    <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Contains the word" name="query" />
                                </div>																								
                                <div className="mb-5">													
                                    <div className="nav-group nav-group-fluid">														
                                        <label>
                                            <input type="radio" className="btn-check" name="type" value="has" checked="checked" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">All</span>
                                        </label>																												
                                        <label>
                                            <input type="radio" className="btn-check" name="type" value="users" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Users</span>
                                        </label>																												
                                        <label>
                                            <input type="radio" className="btn-check" name="type" value="orders" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Orders</span>
                                        </label>																												
                                        <label>
                                            <input type="radio" className="btn-check" name="type" value="projects" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Projects</span>
                                        </label>														
                                    </div>													
                                </div>																								
                                <div className="mb-5">
                                    <input type="text" name="assignedto" className="form-control form-control-sm form-control-solid" placeholder="Assigned to" value="" />
                                </div>																								
                                <div className="mb-5">
                                    <input type="text" name="collaborators" className="form-control form-control-sm form-control-solid" placeholder="Collaborators" value="" />
                                </div>																								
                                <div className="mb-5">													
                                    <div className="nav-group nav-group-fluid">														
                                        <label>
                                            <input type="radio" className="btn-check" name="attachment" value="has" checked="checked" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">Has attachment</span>
                                        </label>																												
                                        <label>
                                            <input type="radio" className="btn-check" name="attachment" value="any" />
                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Any</span>
                                        </label>														
                                    </div>													
                                </div>																								
                                <div className="mb-5">
                                    <select name="timezone" aria-label="Select a Timezone" data-control="select2" data-dropdown-parent="#kt_header_search" data-placeholder="date_period" className="form-select form-select-sm form-select-solid">
                                        <option value="next">Within the next</option>
                                        <option value="last">Within the last</option>
                                        <option value="between">Between</option>
                                        <option value="on">On</option>
                                    </select>
                                </div>																								
                                <div className="row mb-8">													
                                    <div className="col-6">
                                        <input type="number" name="date_number" className="form-control form-control-sm form-control-solid" placeholder="Lenght" value="" />
                                    </div>																										
                                    <div className="col-6">
                                        <select name="date_typer" aria-label="Select a Timezone" data-control="select2" data-dropdown-parent="#kt_header_search" data-placeholder="Period" className="form-select form-select-sm form-select-solid">
                                            <option value="days">Days</option>
                                            <option value="weeks">Weeks</option>
                                            <option value="months">Months</option>
                                            <option value="years">Years</option>
                                        </select>
                                    </div>													
                                </div>																								
                                <div className="d-flex justify-content-end">
                                    <button type="reset" className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2" data-kt-search-element="advanced-options-form-cancel">Cancel</button>
                                    <a href="utilities/search/horizontal.html" className="btn btn-sm fw-bold btn-primary" data-kt-search-element="advanced-options-form-search">Search</a>
                                </div>												
                            </form>																						
                            <form data-kt-search-element="preferences" className="pt-1 d-none">												
                                <h3 className="fw-semibold text-gray-900 mb-7">Search Preferences</h3>																								
                                <div className="pb-4 border-bottom">
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                        <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">Projects</span>
                                        <input className="form-check-input" type="checkbox" value="1" checked="checked" />
                                    </label>
                                </div>																								
                                <div className="py-4 border-bottom">
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                        <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">Targets</span>
                                        <input className="form-check-input" type="checkbox" value="1" checked="checked" />
                                    </label>
                                </div>																								
                                <div className="py-4 border-bottom">
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                        <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">Affiliate Programs</span>
                                        <input className="form-check-input" type="checkbox" value="1" />
                                    </label>
                                </div>																								
                                <div className="py-4 border-bottom">
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                        <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">Referrals</span>
                                        <input className="form-check-input" type="checkbox" value="1" checked="checked" />
                                    </label>
                                </div>																								
                                <div className="py-4 border-bottom">
                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                        <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">Users</span>
                                        <input className="form-check-input" type="checkbox" value="1" />
                                    </label>
                                </div>																								
                                <div className="d-flex justify-content-end pt-7">
                                    <button type="reset" className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2" data-kt-search-element="preferences-dismiss">Cancel</button>
                                    <button type="submit" className="btn btn-sm fw-bold btn-primary">Save Changes</button>
                                </div>												
                            </form>											
                        </div>										
                    </div>									
                </div>	
            														
                <div className="app-navbar-item ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                <div onClick={showusermenu} className="cursor-pointer symbol symbol-35px symbol-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    <img alt="Logo" src={api_assets_url + "upload/" + user.image} />
                </div>
                <div id='usermenu' className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" style={{ zindex: '105', position: 'fixed', 'inset': '0px 0px auto auto', marginRight: "10px", marginTop: "60px" }} data-kt-menu="true">
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5"> 
                          <img alt="Logo" src={api_assets_url + "upload/" + user.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">{user.name}
                            <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">{user.role}</span>
                            </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-2" />
                    <>
                      <div className="menu-item px-5">
                        <Link to={'/profile'} className="menu-link px-5">My Profile</Link>
                      </div>
                    </>
                    <>
                    <div className="menu-item px-5">
                        <Link to={'/password_change'} className="menu-link px-5">Password Change</Link>
                      </div>
                      </>
                  <div className="menu-item px-5">
                    <a  href="#" onClick={handleSignOut} className="menu-link px-5" >Sign Out</a>
                  </div>
                
                </div>
               
              </div>
              											
               																								
            </div>							
        </div>						
    </div>					
</div>	
  );
};

export default Header;