import React, { createContext,useState,useEffect } from 'react'
export const AuthContext = createContext(null);
function AuthProvider({children}) {
    const [user,setUser] = useState([]);
    const [islogin,setislogin] = useState(false);
    const login=(usersdata)=>{   
        setUser(usersdata);  
    }
    const logout=()=>{
        setUser([]);
        localStorage.removeItem('userData');
        setislogin(false);
       
    }
    useEffect(() => {		
      
        }, []);
    return (
        <AuthContext.Provider value={{user,login,logout,islogin}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
