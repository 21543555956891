import React, { useEffect,useState ,useContext} from "react";
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
function InvesmentAmount() {
  const navigate = useNavigate();
  const {user,login} = useContext(AuthContext); 
    const [utr, setutr] = useState("");
    const [amount, setamount] = useState("");
    const [amountError, setamountError] = useState('');
    const [walletError, setwalletError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
          const response = await fetch(api_url + `profileview/${user.id}`, {
            method: 'GET',
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          login(data)
		  localStorage.setItem('userData', JSON.stringify(data));		
        
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
    const onhandaltdocumentsubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
          return;
        }
        
        setLoading(true);
        const formdata = new FormData();
        formdata.append("uid", user.id);
        formdata.append("type",2);
        formdata.append("amount", amount);
        fetch(api_url + "invesmentandwallet", {
          method: "POST",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              setLoading(false);
              toast.success("Please wait for admin approval", {
                duration: 1000, 
              });
              fetchData();
            //   navigate('/supportlist')
            } else {
              toast.error("Insert failed");
            }
          })
          .catch((error) => {
            console.error("Insert error:", error);
          });
      };
      const validateForm = () => {
   
        setamountError("");
        
       
        let isValid = true;
        
        if (amount.trim() === "") {
          setamountError("amount is required");
          isValid = false;
        }
        console.log("---");
       
if (parseInt(amount.trim()) > parseInt(user.invesment_amount)) {

            toast.error("Withdrawal amount is greater than your investment amount");
            isValid = false;
          }
        return isValid;
       };
      
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
                
                    {/* <div className="d-flex align-items-center gap-2 gap-lg-3">										
                                    <a href="#" className="btn btn-sm fw-bold btn-secondary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Rollover</a>																				
                                    <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Target</a>										
                                </div>									 */}
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div className="row gx-10 gx-xl-10" style={{padding:'12px'}}>
                      <div className="col-xxl-12 mb-5 mb-xl-12">
                        <div className="card card-flush h-xl-100">
                          <div className="row">
                          <div className="col-xxl-1 mb-5 mb-xl-1">
                            </div>
                            <div className="col-xxl-10 mb-5 mb-xl-10">
                            <form onSubmit={onhandaltdocumentsubmit}>

<div className="mt-5">
<label style={{ padding: "0px 15px" }} className="form-label text-start">
Bank Details
</label>

<div>
<label style={{ padding: "0px 15px" }}>Account Name:</label>
<span>{user.bankname}</span>
</div>
<div>
<label style={{ padding: "0px 15px" }}>Account Number:</label>
<span>{user.accountnumber}</span>
</div>

<div>
<label style={{ padding: "0px 15px" }}>IFSC Code:</label>
<span>{user.ifsccode}</span>
</div>

</div>



                           
                            <div className="mt-4">
                              <label style={{padding:"0px 15px"}} className=" form-label text-start">
                                AMOUNT
                              </label>
                              <input
                              
                                type="text"
                                onChange={(e) => {setamount(e.target.value);setamountError('')}}
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter amount"
                              />
                                 {amountError && <div className="text-danger">{amountError}</div>}
                            </div>
                           
                            <div className="mt-5" style={{textAlign:'center'}}>
                            <button
                                type="submit"
                                className="ntnsubmit"
                                style={{
                                  width: '50%',
                                  padding: '6px',
                                  color: '#FFFFFF',
                                  fontWeight: '700',
                                  fontSize: '15px',
                                  background: isLoading ? "gray" : "#232323",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandaltdocumentsubmit}
                                // disabled={isLoading}
                              >
                                {isLoading ? 'Loading...' : 'Submit'}
                              </button>
                            </div>
                          </form>
                            </div>
                          </div>
                       
                        </div>
                      </div>
                      <div className="col-xl-6 mb-5 mb-xl-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default InvesmentAmount;
