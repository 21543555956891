import { createBrowserRouter } from "react-router-dom";
import Login from "../component/Login";
import Signup from "../component/Signup";
import AuthGaurd from "../component/AuthGaurd";
import Dashboard from "../component/Dashboard";
import Error from "../component/Error";
import Support from "../component/Support";
import SupportList from "../component/Supportlist";
import Loansetting from "../component/Loansetting";
import Loansettinglist from "../component/Loansettinglist";
import Request from "../component/Request";
import Requestlist from "../component/Requestlist";
import Userlist from "../component/Userlist";
import Profile from "../component/Profile";
import Mobilemenu from "../component/Mobilemenu";
import Passwordchange from "../component/Passwordchange";
import InvesmentAmount from "../component/InvesmentAmount";
import WithdrawalAmount from "../component/WithdrawalAmount";
import Myinvestment from "../component/Myinvestment";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "*",
    element: <Error />,
  },
  {
    path: "/",
    element: <AuthGaurd />,
    children: [
      {
        path: "/dasboard",
        element: <Dashboard />,
      },

      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/supportlist",
        element: <SupportList />,
      },
      {
        path: "/loan_setting",
        element: <Loansetting />,
      },
      {
        path: "/loan_setting_list",
        element: <Loansettinglist />,
      },
      {
        path: "/request",
        element: <Request />,
      },
      {
        path: "/requestlist",
        element: <Requestlist />,
      },
      {
        path: "/userslist/:type",
        element: <Userlist />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/mobilemenu",
        element: <Mobilemenu />,
      },
      {
        path: "/password_change",
        element: <Passwordchange />,
      },
      {
        path: "/investment",
        element: <InvesmentAmount />,
      },
      {
        path: "/withdrawal",
        element: <WithdrawalAmount />,
      },
      {
        path: "/myinvestment",
        element: <Myinvestment />,
      },
    ],
  },
]);

export default router;