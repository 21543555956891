import React, { useState, useEffect } from 'react';

function CountdownTimer({ timestamp }) {
  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = (timestamp * 1000) - now;
    
    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    } else {
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      return { hours, minutes, seconds };
    }
  }

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <div>
      <p>{`${timeRemaining.hours.toString().padStart(2, '0')} : ${timeRemaining.minutes.toString().padStart(2, '0')} : ${timeRemaining.seconds.toString().padStart(2, '0')} `}</p>
    </div>
  );
}

export default CountdownTimer;
