import React, { useEffect, useState ,useContext} from "react";
import {api_url,api_assets_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {useParams } from 'react-router-dom';
import Select from "react-select";
import {
    Modal,
    ModalFooter,
    ListGroup,
    ListGroupItem,
    Label,
    CardBody,
    CardText,
    CardTitle,
    ModalBody,
    ModalHeader,
    FormFeedback,
    CardHeader,
    UncontrolledTooltip,
  } from "reactstrap";
function Userlist() {
  const [loanseeker, setloanseeker] = useState([]);
  const [viewloanseeker, setviewloanseeker] = useState(false);
  const [view_data, setview_data] = useState([]);
  let { type } = useParams();

  
  useEffect(() => {
    fetchData();
    console.log(type);
  }, [type]);
  const fetchData = async () => {
    try {
      const response = await fetch(api_url + `getuserlist/${type}`, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setloanseeker(data); 
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const setloanseekerview = (id) =>{
    fetch(api_url + 'viewloanseeker/' + id, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        setview_data(data)
        fetchData();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
   
   
  }
  return (
    <>
      <div
        classNameName="d-flex flex-column flex-root app-root"
        id="kt_app_root"
      >
        <div
          classNameName="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            classNameName="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              classNameName="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div classNameName="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  classNameName="app-toolbar py-3 py-lg-6"
                >
                  <div
                    id="kt_app_toolbar_container"
                    classNameName="app-container container-fluid d-flex flex-stack"
                  >
                    <div classNameName="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                      <h1 classNameName="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                      {/* LoanSeeker List */}
                      </h1>
                      <ul classNameName="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li classNameName="breadcrumb-item text-muted">
                          <a
                            href="index.html"
                            classNameName="text-muted text-hover-primary"
                          >
                            Home
                          </a>
                        </li>
                        <li classNameName="breadcrumb-item">
                          <span classNameName="bullet bg-gray-500 w-5px h-2px"></span>
                        </li>
                        <li classNameName="breadcrumb-item text-muted">
                          Dashboards
                        </li>
                      </ul>
                    </div>
                   							
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  classNameName="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    classNameName="app-container container-fluid"
                  >
                    <div
                      className="row gy-5 g-xl-10"
                      data-select2-id="select2-data-126-jy4v"
                    >
                      <div className="col-xl-3 mb-xl-10">
                        
                      </div>

                      <div
                        className="col-xl-8  mb-xl-10"
                        data-select2-id="select2-data-125-pj7l"
                      >
                        <div
                          className="card card-flush h-xl-100"
                          data-select2-id="select2-data-124-kptd"
                        >
                          <div
                            className="card-header pt-7"
                            data-select2-id="select2-data-123-37nk"
                          >
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                              LoanSeeker List
                              </span>
                              <span className="text-gray-500 mt-1 fw-semibold fs-6">
                              
                              </span>
                            </h3>

                            <div className="card-toolbar">
                              <div className="d-flex flex-stack flex-wrap gap-4">
                                <div className="position-relative my-1">
                                  <i className="ki-duotone ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <input
                                    type="text"
                                    data-kt-table-widget-4="search"
                                    className="form-control w-150px fs-7 ps-12"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body pt-2">
                            <div
                              id="kt_table_widget_4_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_4_table"
                                >
                                  <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        ID
                                      </th>
                                      <th
                                        className="text-end min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        name
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        mobile
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        email
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        address
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        adhar
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        pan
                                      </th>
                                     
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        Action
                                      </th>
                                      
                                    </tr>
                                  </thead>

                                  <tbody className="fw-bold text-gray-600">
                                  {loanseeker.map((item, index) => (
                                    <tr className="odd" key={index}>
                                      <td>
                                       
                                       {index + 1}
                                   
                                      </td>
                                      <td className="text-end">{item.name}</td>
                                      <td className="text-end">
                                        
                                         {item.mobile}
                                    
                                      </td>
                                      <td className="text-end">
                                        
                                         {item.email}
                                       
                                      </td>

                                      <td className="text-end">
                                        
                                         {item.address}
                                        
                                      </td>
                                      <td className="text-end">
                                       
                                         {item.adhar}
                                      
                                      </td>
                                      <td className="text-end">
                                        
                                         {item.pan}
                                      
                                      </td>
                                     

                                      
                                      <td className="text-end">
                                      <button
                                          type="button"
                                   
                                          onClick={() => { setviewloanseeker(true); setloanseekerview(item.id);}}
                                          className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px me-2"
                                          data-kt-table-widget-4="expand_row"
                                        >
                                        <i className="ki-duotone ki-eye">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        </i>
                                        </button>
                                        {/* <button
                                          type="button"
                                          className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                          data-kt-table-widget-4="expand_row"
                                        >
                                         <i className="ki-duotone ki-pencil">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        </i>
                                        </button> */}
                                        </td>
                                     
                                    </tr>
                                  ))} 
                                     <Modal
                                          isOpen={viewloanseeker}
                                          toggle={() => setviewloanseeker(!viewloanseeker)}
                                          className="modal-dialog-centered modal-lg"
                                        >
                                          
                                          <ModalHeader
                                          
                                            className="bg-transparent"
                                            toggle={() => setviewloanseeker(!viewloanseeker)}
                                          ><h5 className="mb-1 text-center">
                                          View's
                                          </h5></ModalHeader>
                                        
                                          <ModalBody
                                              className="px-sm-5 mx-50 pb-5"
                                              style={{ maxHeight: "400px", overflowY: "scroll" }}
                                            >
                                               {view_data && (
                                              <div className="mb-5">
                                               <div style={{ position: 'relative' }}>
                                                <div style={{ position: 'absolute', top: -25, left: 0 }}>
                                                    <h5>Bank Details: {view_data.bankd}</h5>
                                                </div>
                                                <div style={{ position: 'absolute', top: -59, right: 45 }}>
                                                    <img
                                                        src={api_assets_url + "upload/" + view_data.image}
                                                        alt="img-fluid"
                                                        style={{ width: "100px", height: "100px" }} 
                                                    />
                                                </div>
                                                
                                                <p className="mt-5">Role Name: {view_data.role}</p> 
                                             <p className="mt-5">Date: {view_data.regdate}</p> 
                                            </div>
                                            <div className="mb-5" style={{ position: 'relative' }}>
                                                <div className="" style={{ position: 'absolute', top: -35, right: 45 }}>
                                                    <img
                                                        src={api_assets_url + "upload/" + view_data.statementimage}
                                                        alt="img-fluid"
                                                        style={{ width: "100px", height: "100px" }} 
                                                    />
                                                </div>
                                                
                                              
                                            
                                            </div>
                                               
                                              </div>
                                              
                                           
                                              
                                          )}
                                            </ModalBody>
                                           
                                           
                                   
                                        </Modal>   
                                  </tbody>
                                
                                </table>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-1 mb-xl-10">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Userlist;
