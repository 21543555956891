import React, { useEffect, useState, useContext } from "react";
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";;
function Loansetting() {
  const { user } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const onhandaltdocumentsubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        return;
      }
    setLoading(true);
    const formdata = new FormData();
    formdata.append("oldpassword", oldpassword);
    formdata.append("newpassword", newpassword);
    formdata.append("userid", user.id);
    fetch(api_url + "passwordchange", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message == 1) {
          toast.success("Update Successfully", {
            duration: 3000,
          });
          setoldpassword("");
          setnewpassword("");
          setconfirmpassword("");
          setLoading(false);          
        } else {
          toast.error("Old password not match");
          setLoading(false); 
        }
      })
      .catch((error) => {
        console.error("Insert error:", error);
      });
  };
  const validateForm = () => {
    let isValid = true;
    if (oldpassword.trim() === "") {
        toast.error("Old Password is required");
        isValid = false;
        return
      }
      if (newpassword.trim() === "") {
        
        toast.error("Password is required");
        isValid = false;
        return
      }
      if(newpassword !== confirmpassword) {
        toast.error("Confirm password not match");
        isValid = false;
        return
      }
    return isValid;
   };

  
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
                
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div class="container">
                      <div class="row">
                        <div class="col-md-3 col-xl-3 text-start">
                        </div>

                        <div class="col-md-9 col-xl-9 personal-info">
                        <h3 style={{marginTop:"10px",marginBottom:"10px"}}>
                             Change Password
                          </h3>

                          <form class="form-horizontal" role="form">
                            <div class="form-group style">
                              <label class="col-md-3 control-label">
                                Old password
                              </label>
                              <div class="col-md-8">
                                <input
                                  class="form-control"
                                  type="password"
                                  value={oldpassword}
                                  onChange={(e) => {setoldpassword(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                                New password
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="password"
                                  value={newpassword}
                                  
                                  onChange={(e) => {setnewpassword(e.target.value)}}
                                />
                              </div>
                            </div>
                            <div class="form-group style">
                              <label class="col-lg-3 control-label">
                              Confirm Password
                              </label>
                              <div class="col-lg-8">
                                <input
                                  class="form-control"
                                  type="password"
                                value={confirmpassword}
                                  onChange={(e) => {setconfirmpassword(e.target.value)}}
                                />
                              </div>
                            </div>


                           
                            <div class="form-group style">
                              <label class="col-md-3 control-label"></label>
                              <div class="col-md-8">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{
                                  background: isLoading ? "gray" : "primary",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandaltdocumentsubmit}
                              >
                                {isLoading ? 'Loading...' : 'Submit'}
                              </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Loansetting;
