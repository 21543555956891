import React, { useContext, useEffect } from 'react'
import {Outlet, useNavigate} from 'react-router-dom';
import { AuthContext } from './AuthProvider';
function AuthGaurd() {
    const {islogin} = useContext(AuthContext);
    const navigate = useNavigate();   
    return (
        <div>              
            <Outlet/>               
        </div>
    )
}

export default AuthGaurd
