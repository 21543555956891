import React, { useEffect,useState ,useContext} from "react";
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
function Support() {
  const navigate = useNavigate();
  const {user} = useContext(AuthContext); 
    const [Title, setTitle] = useState("");
    const [subject, setsubject] = useState("");
    const [documentimage, setdocumentimage] = useState("");
    const [titleError, settitleError] = useState('');
    const [subjectError, setsubjectError] = useState('');
    const [documentError, setdocumentError] = useState('');

    const [isLoading, setLoading] = useState(false);
    const handleDocumentChange = (event) => {
        const file = event.target.files[0];
        setdocumentimage(file);
        setdocumentError('');
    }
    
    const onhandaltdocumentsubmit = (e) => {
        e.preventDefault();
      
    
        if (!validateForm()) {
          return;
        }
        setLoading(true);
        const formdata = new FormData();
        formdata.append("userid", user.id);
        formdata.append("roletype",user.roleid);
        formdata.append("title", Title);
        formdata.append("subject", subject);
        formdata.append("documentimage", documentimage);

        fetch(api_url + "addsupport", {
          method: "POST",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              setLoading(false);
              toast.success("insert Successfully", {
                duration: 1000, 
              });
              navigate('/supportlist')
            } else {
              toast.error("Insert failed");
            }
          })
          .catch((error) => {
            console.error("Insert error:", error);
          });
      };
      const validateForm = () => {
        settitleError("");
        setsubjectError("");
        
       
        let isValid = true;
       
       
        if (Title.trim() === "") {
          settitleError("Title is required");
          isValid = false;
        }
        if (subject.trim() === "") {
          setsubjectError("Subject is required");
          isValid = false;
        }
        if(documentimage == ""){
          console.log("image must not be empty");
          setdocumentError("image must not be empty");
          isValid = false;
        }           
        return isValid;
       };
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
                
                    {/* <div className="d-flex align-items-center gap-2 gap-lg-3">										
                                    <a href="#" className="btn btn-sm fw-bold btn-secondary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Rollover</a>																				
                                    <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Target</a>										
                                </div>									 */}
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div className="row gx-10 gx-xl-10">
                      <div className="col-xxl-12 mb-5 mb-xl-12">
                        <div className="card card-flush h-xl-100">
                          <div className="row">
                          <div className="col-xxl-1 mb-5 mb-xl-1">
                            </div>
                            <div className="col-xxl-10 mb-5 mb-xl-10">
                            <form onSubmit={onhandaltdocumentsubmit}>
                            <div className="mt-5">
                              <label style={{padding:"0px 15px"}} className="form-label text-start">
                                Title
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {setTitle(e.target.value);settitleError('')}}
                                className="form-control form-control-solid"
                       
                                placeholder="Enter Title"
                              />
                                 {titleError && <div className="text-danger">{titleError}</div>}
                            </div>
                            <div className="mt-4">
                              <label style={{padding:"0px 15px"}} className=" form-label text-start">
                                Subject
                              </label>
                              <input
                              
                                type="text"
                                onChange={(e) => {setsubject(e.target.value);setsubjectError('')}}
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter subject"
                              />
                                 {subjectError && <div className="text-danger">{subjectError}</div>}
                            </div>
                            <div className="mt-4">
                              <label style={{padding:"0px 15px"}} className=" form-label text-start">
                                Document
                              </label>
                              <input
                                type="file"
                                onChange={handleDocumentChange}
                                // className={`form-control form-control-lg form-control-solid ${
                                //   documentError ? "is-invalid" : ""
                                // }`} 
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter subject"
                              />
                                 {documentError && <div className="text-danger">{documentError}</div>}
                            </div>
                            <div className="mt-5" style={{textAlign:'center'}}>
                            <button
                                type="submit"
                                className="ntnsubmit"
                                style={{
                                  width: '50%',
                                  padding: '6px',
                                  color: '#FFFFFF',
                                  fontWeight: '700',
                                  fontSize: '15px',
                                  background: isLoading ? "gray" : "#232323",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandaltdocumentsubmit}
                                // disabled={isLoading}
                              >
                                {isLoading ? 'Loading...' : 'Submit'}
                              </button>
                            </div>
                          </form>
                            </div>
                          </div>
                       
                        </div>
                      </div>
                      <div className="col-xl-6 mb-5 mb-xl-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Support;
