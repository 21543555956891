import React, { useEffect, useState,useContext } from "react";
import {api_url } from "../common/Helper.js";
import Header from "../common/Header.js";
import SidebarMenu from "../common/Sidebarmenu.js";
import {AuthContext} from './AuthProvider.js';
import CountdownTimer from "./CountdownTimer.js";
import Select from "react-select";
import {
  Modal,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Label,
  CardBody,
  CardText,
  CardTitle,
  ModalBody,
  ModalHeader,
  FormFeedback,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
function Requestlist() {
  const [loanrequestlist, setloanrequestlist] = useState([]);
  const {user} = useContext(AuthContext); 
  const [investerid, setinvesterid] = useState([]);  
  const [itemid, setitemid] = useState('');
  const [Reply, setReply] = useState(false);
  const [investeroption, setinvesteroption] = useState([]);
  const [status, setstatus] = useState('');
  const [hourid, sethourid] = useState(false);
  const [requestid, setrequestid] = useState([]);
  const handleChange = (event) => {
    const id = event.value;
    const name = event.label;
    setinvesterid(id);    
  };
  const paynow = (itemid,amount,mobile,name) => { 
  
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("amount", amount);
    formdata.append("number", mobile);
    formdata.append("reqid", itemid);
      fetch(api_url + "pay", {
        method: "POST",
        body: formdata,
      })
      .then((response) => {
        if (!response.ok) {
          // Handle non-200 responses
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        if(data.success==true){
          window.location.href = data.data.instrumentResponse.redirectInfo.url;
        }
      })
      .catch((response) => {        
        console.error("error:", response);
      });
  }
  
  const statusUpdate = (status,hourid,requestid) => { 
    const formdata = new FormData();
    formdata.append("status", status);
    formdata.append("hourid", hourid);
    formdata.append("requestid", requestid);
    fetch(api_url + "statusforrequestload", {
      method: "POST",
      body: formdata,
    })
    .then((response) => {
      if (!response.ok) {
        // Handle non-200 responses
        throw response;
      }
      return response.json();
    })
    .then((data) => {
      if(user.id > 0){
        fetchData();
      }
    })
    .catch((response) => {        
      console.error("error:", response);
    });
  };
  const investersubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("ass_id", investerid);
    formdata.append("requestid", requestid);
    fetch(api_url + "assigntoinvestor", {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        if (!response.ok) {
     
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        statusUpdate(status,hourid,requestid)
          fetchData();
        
      })
      .catch((response) => {
        console.error("error:", response);
      });
  };
  const fetchData = async () => {    
    try {      
      const response = await fetch(api_url + 'getrequest/'+ user.roleid+'/'+user.id, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setloanrequestlist(data);
     console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetch(api_url + "getinvestor")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        let arrissue = [];
        arrissue.push({ value: 0, label: "Select..." });
        if (data.length > 0) {
          console.log(data);
          for (let x in data) {
            let obj = {
              value: data[x].id,
              label: data[x].name,
            };
            arrissue.push(obj);
          }
        }
        console.log(arrissue)
        setinvesteroption(arrissue);
       
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    if(user.id > 0){
      fetchData();
    }
  }, [user.id]);
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex flex-stack"
                  >
                
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-xxl"
                  >
                    <div className="row g-5 g-xl-10 mb-xl-10">
  {loanrequestlist.map((item) => (
    <div key={item.id} className="col-md-6 col-lg-6 col-xxl-3  col-xl-3 mb-md-5 mb-xl-10">
      <div className="card border-hover-primary">
        <div className="card-header border-0 pt-9">
          <div className="card-title m-0">  
          <div class="fs-3 fw-bold text-gray-900">Name:{item.name}</div>
          </div>

          <div className="card-toolbar">
          <span className="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{ backgroundColor: 
  item.status === 1 ? 'green' : 
  item.status === 2 ? 'red' : 
  item.status === 3 ? 'blue' : // Assuming blue for "Paid"
  'orange',
  color:'#fff'
}}>
  {item.status === 1 ? "Approved" : 
    item.status === 2 ? "Rejected" : 
    item.status === 3 ? "Paid" : // Display "Paid" for status 3
    "Pending"}
</span>
          </div>
        </div>
      
        <p class="text-gray-500 fw-bold fs-5 mt-1 mb-7" style={{padding:'0px 27px'}}>number:{item.mobile}</p>

        <div className="card-body p-9">
          <div className="d-flex flex-wrap mb-5">
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
              <div className="fs-6 text-gray-800 fw-bold">
                {item.hour} Hrs
              </div>
              <div className="fw-semibold text-gray-500">
                Time
              </div>
            </div>

            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
              <div className="fs-6 text-gray-800 fw-bold">
                {item.amount}
              </div>
              <div className="fw-semibold text-gray-500">
                Amount
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap mb-5">
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
              <div className="fs-6 text-gray-800 fw-bold">
                    {item.rdate}                      
              </div>
              <div className="fw-semibold text-gray-500">
                Date
              </div>
            </div>

            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
              <div className="fs-6 text-gray-800 fw-bold">
              {item.status == 1 &&(
                  <CountdownTimer timestamp={item.completed_approve}/>
                )}     
              </div>
              <div className="fw-semibold text-gray-500">
                Time
              </div>
            </div>

            {item.status == 0 && user.roleid == 1 &&(
    <div className="me-auto"> {/* Left-align buttons */}    
      <button className="btn btn-primary btn-sm mt-2" onClick={() => {
        setReply(true);
        setitemid(item.itemid)
        setstatus(2);
        sethourid(item.hourid);
        setrequestid(item.itemid);
      }
      }>Approve</button>
      <button className="btn btn-warning btn-sm mt-2 ms-2" onClick={() => statusUpdate(2, item.hourid, item.itemid)}>Cancel</button>
    </div>
  )}
 {(item.status < 2 &&  user.roleid == 2 &&  user.status > 0) &&(

    <div className="me-auto"> 
      <button name="" className="btn btn-primary btn-sm mt-2 " onClick={() => paynow(item.itemid,item.amount,item.mobile,item.name)}>Pay Now</button>      
    </div>
  )}
          </div>
        </div>
      </div>
    </div>
  ))}
  <Modal
                        isOpen={Reply}
                        toggle={() => setReply(!Reply)}
                        className="modal-dialog-centered modal-lg"
                      >
                        <ModalHeader
                          className="bg-transparent"
                          toggle={() => setReply(!Reply)}
                        ></ModalHeader>
                        <form enctype="multipart/form-data"  onSubmit={investersubmit}>
                          <ModalBody
                            className="px-sm-5 mx-50 pb-5"
                            style={{ maxHeight: "400px", overflowY: "scroll" }}
                          >
                            <div className="text-center mb-2">
                              <h1 className="mb-1">Reply</h1>
                            </div>
                            <div>
                              <label
                               className="form-label"
                              >
                                Message
                              </label>
                             <Select
                                
                                id="select"
                                options={investeroption}
                                onChange={handleChange}
                                className=""
                              />
                            </div>
                          </ModalBody>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <button
                                type="button"
                                className="mt-3"
                                style={{ margin: "40px" }}
                                onClick={investersubmit}
                              >
                                Assign to invester 
                              </button>
                            </div>
                          </div>
                        </form>
                      </Modal>
</div>



                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Requestlist;
