import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Switch, Route, Routes } from'react-router-dom';
import Login from './component/Login';
import Signup from './component/Signup';
function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Login/>}></Route>
          <Route path='/signup' element={<Signup/>}></Route>
          <Route path="*" element={<h1>404</h1>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
