import React, { useState, useEffect, useContext } from "react";
import {isEmail,app_url,api_url} from "../common/Helper";
import { useNavigate,Link, json } from 'react-router-dom';
import {AuthContext} from './AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const Login = () => {
	const navigate = useNavigate();
	const [number, setnumber] = useState("");
	const [password, setpassword] = useState(""); 
	const [isLoading, setLoading] = useState(false);
	const {login} = useContext(AuthContext);
	  const onhandalsubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const formdata = new FormData();
		formdata.append("number", number);
		formdata.append("password", password);
		fetch(api_url + "login", {
		  method: "POST",
		  body: formdata,
		})
		.then((response) => {
		  setLoading(false);
		  if (!response.ok) {
			throw response;
		  }
		  return response.json();
		})
		.then((data) => {
		  if(data.message == 2){
			  toast.error("Invalid credentials");
			  return
		  }
		  if(data.message == 3){
			  toast.error("User not found");
			  return
		  }
		  // Small 
		  toast.success("Login Successfully", {
			duration: 500,
		  });
		  login(data.user)
		  localStorage.setItem('userData', JSON.stringify(data.user));		  
		  setTimeout(() => {
			navigate('/dasboard');
		  }, 1500);
		  
		})
		.catch((response) => {
		  // Handle HTTP errors
		  if (response.status === 401) {
			response.json().then((data) => {
			  toast.error("Sign in failed: " + data.message);
			});
		  } else if (response.status === 404) {
			response.json().then((data) => {
			  toast.error("User not found: " + data.message);
			});
		  } else {
			response.json().then((data) => {
			  toast.error("Error: " + data.message);
			});
		  }
		  console.error("Sign in failed:", response);
		});
	  };
	  useEffect(() => {		
		const user = localStorage.getItem('userData');
		if(user){			
			const data = JSON.parse(user)							
			login(data)	
			navigate('/dasboard');
		}		
		}, []);
  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root" style={{height:'100%'}}>
			
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				
				<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
				
					<div className="d-flex flex-center flex-column flex-lg-row-fluid">
					
						<div className="" style={{width:'100%',marginTop:'100px'}}>
						
							<form className="form" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="index.html"  onSubmit={onhandalsubmit}>
								
								<div className="text-center mb-11">
								
									<h1 className="text-gray-900 fw-bolder mb-3" style={{fontSize:'25px'}}>Sign In</h1>
																	
								</div>
								<div className="fv-row mb-8">
									
									<input type="number" placeholder="Number" name="number"  className="form-control bg-transparent" 
									value={number}
									onChange={(event) => {
									  const input = event.target.value;
									  const regex = /^[0-9]{0,10}$/;
									  if (regex.test(input)) {
										setnumber(input);
									  }
									}} />
									
								</div>
								
								<div className="fv-row mb-3">
								
									<input type="password" placeholder="Password" name="password"  className="form-control bg-transparent" onChange={(e) => {setpassword(e.target.value)}}/>
									
								</div>
							
								<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
									<div></div>
									
									
								</div>
							
								<div className="d-grid mb-10">
								<button
                                type="submit"
                                className="btn btn-primary"
                                style={{
                                  background: isLoading ? "gray" : "primary",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandalsubmit}
                              >
                                {isLoading ? 'Loading...' : 'Sign In'}
                              </button>
									{/* <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
										
										<span className="indicator-label">Sign In</span>
										
										
										<span className="indicator-progress">Please wait... 
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									
									</button> */}
								</div>
								
								<div className="text-gray-500 text-center " style={{fontSize:'18px'}}>Not a Member yet? 
								<Link to={"/signup"} className="link-primary" style={{fontWeight:'700'}}> Sign up</Link></div>
								
							</form>
							
						</div>
						
					</div>
					
				
					
				</div>
			
				<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: "url('assets/media/misc/auth-bg.png')" }}>
					
					<div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
						
						<a href="index.html" className="mb-0 mb-lg-12">
							<img alt="Logo" src="assets/logo-white.png" className="h-60px h-lg-75px" />
						</a>
						
						<img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="assets/media/misc/auth-screens.png" alt="" />
						
						<h1 className="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
						
						<div className="d-none d-lg-block text-white fs-base text-center">In this kind of post, 
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed 
						<br />and provides some background information about 
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their 
						<br />work following this is a transcript of the interview.</div>
						
					</div>
					
				</div>
				
			</div>
			<ToastContainer/>
		</div>
	
  );
};

export default Login;
