import React, { useEffect,useState,useContext } from "react";
import {api_url } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from './AuthProvider';
function Loansetting() {
  const {user} = useContext(AuthContext); 
    const [hour, sethour] = useState("");
    const [intresat, setintresat] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [hourError, setHourError] = useState('');
   const [intresatError, setIntresatError] = useState('');
    const onhandaltdocumentsubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
          return;
        }
        setLoading(true);
        const formdata = new FormData();
        formdata.append("userid", user.id);
        formdata.append("roletype", user.roleid);
        formdata.append("hour", hour);
        formdata.append("intresat", intresat);
        fetch(api_url + "addloansetting", {
          method: "POST",
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              toast.success("insert Successfully", {
                duration: 3000, 
              });
              setLoading(false);              
            } else {
              toast.error("Insert failed");
            }
          })
          .catch((error) => {
            console.error("Insert error:", error);
          });
      };
      const validateForm = () => {
        setHourError("");
        setIntresatError("");
       
        let isValid = true;
       
        if (hour.trim() === "") {
          setHourError("Hour is required");
          isValid = false;
        }
        //batch id
        if (intresat.trim() === "") {
          setIntresatError("Intresat is required");
          isValid = false;
        }
      
        return isValid;
       };
  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                  <div
                    id="kt_app_toolbar_container"
                    className="app-container container-fluid d-flex flex-stack"
                  >
               
                    {/* <div className="d-flex align-items-center gap-2 gap-lg-3">										
                                    <a href="#" className="btn btn-sm fw-bold btn-secondary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Rollover</a>																				
                                    <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Target</a>										
                                </div>									 */}
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <div className="row gx-10 gx-xl-10">
                      <div className="col-xxl-12 mb-5 mb-xl-12">
                        <div className="card card-flush h-xl-100">
                        <div className="row mt-5">
                          <div className="col-xxl-1 mb-5 mb-xl-1">
                          </div>
                            <div className="col-xxl-10 mb-5 mb-xl-10">
                            <form onSubmit={onhandaltdocumentsubmit}>
                            <div>
                            <label style={{padding:"0px 15px"}} className="form-label required">
                              Hour
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {sethour(e.target.value);setHourError('')}}
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter Title"
                              />
                                 {hourError && <div className="text-danger">{hourError}</div>}
                            </div>
                            <div className="mt-4">
                            <label style={{padding:"0px 15px"}} className="form-label required">
                              Rate Intresat
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {setintresat(e.target.value);setIntresatError('')}}
                                className="form-control form-control-solid"
                                required
                                placeholder="Enter subject"
                              />
                                {intresatError && <div className="text-danger">{intresatError}</div>}
                            </div>
                            
                            <div className="mt-5" style={{textAlign:'center'}}>
                               <button
                                type="submit"
                                className="ntnsubmit"
                                style={{
                                  background: isLoading ? "gray" : "aquamarine",
                                  border: "1px solid #FFFFFF",
                                  cursor: isLoading ? "gray" : "aquamarine",
                                }}
                                onClick={onhandaltdocumentsubmit}
                              >
                                {isLoading ? 'Loading...' : 'Submit'}
                              </button>
                            </div>
                          </form>
                            </div>
                        </div>
                         
                        </div>
                      </div>
                      <div className="col-xl-6 mb-5 mb-xl-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}
export default Loansetting;
