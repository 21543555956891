export const app_url = '/';
export const api_url = 'https://essane.in/loanapi/api/';
export const api_assets_url ="https://essane.in/loanapi/storage/app/public/";  
// export const site_url = "http://localhost:3001/";
// export const api_url ="http://localhost/akcapi/api/";
export function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
  export function numberToWords(amount) {
    const numbersToWords = {
        0: 'zero',
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
        6: 'six',
        7: 'seven',
        8: 'eight',
        9: 'nine',
        10: 'ten',
        11: 'eleven',
        12: 'twelve',
        13: 'thirteen',
        14: 'fourteen',
        15: 'fifteen',
        16: 'sixteen',
        17: 'seventeen',
        18: 'eighteen',
        19: 'nineteen'
    };
    const tensWords = {
        2: 'twenty',
        3: 'thirty',
        4: 'forty',
        5: 'fifty',
        6: 'sixty',
        7: 'seventy',
        8: 'eighty',
        9: 'ninety'
    };

    const convertLessThanThousand = (number) => {
        if (number === 0) {
            return '';
        } else if (number < 20) {
            return `${numbersToWords[number]}`;
        } else if (number < 100) {
            const tens = Math.floor(number / 10);
            const ones = number % 10;
            return `${tensWords[tens]} ${numbersToWords[ones]}`.trim();
        } else {
            const hundreds = Math.floor(number / 100);
            const remainder = number % 100;
            return `${numbersToWords[hundreds]} hundred ${convertLessThanThousand(remainder)}`.trim();
        }
    };

    if (amount === 0) {
        return 'zero';
    }

    const scales = ['', 'thousand', 'million', 'billion', 'trillion'];
    let scaleIndex = 0;
    let result = '';

    while (amount > 0) {
        const chunk = amount % 1000;
        if (chunk !== 0) {
            result = `${convertLessThanThousand(chunk)} ${scales[scaleIndex]} ${result}`.trim();
        }
        amount = Math.floor(amount / 1000);
        scaleIndex++;
    }

    return result;
}
export function convertDateFormat(dateString) {
    // Convert the dateString to a string if it's not already
    const dateStringStr = String(dateString);

    // Split the date string into day, month, and year components
    const day = dateStringStr.substring(6, 8);
    const month = dateStringStr.substring(4, 6);
    const year = dateStringStr.substring(0, 4);

    // Construct the new date string in the desired format
    const newDateFormat = `${day}-${month}-${year}`;

    return newDateFormat;
}

