import React from "react";

function Error() {
  return (
    <div>
      <h2>Error</h2>
      <p>Sorry, an error occurred.</p>
    </div>
  );
}

export default Error;
