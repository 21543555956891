import React, { useEffect, useState,useContext } from "react";
import {api_url,convertDateFormat } from "../common/Helper";
import Header from "../common/Header";
import SidebarMenu from "../common/Sidebarmenu";
import Select from "react-select";
import { AuthContext } from "./AuthProvider";
import { ToastContainer, toast } from 'react-toastify';
function Myinvestment() {
  const [myinvestment, setmyinvestment] = useState([]);
  const {user} = useContext(AuthContext); 
  useEffect(() => {
    if(user.id>0){
        fetchData();
    }
  }, [user.id]);
const handleClickStatusupdate = (iid,amount,type,status) => {
  
    const formdata = new FormData();
    formdata.append("userid", user.id);
    formdata.append("id", iid);
    formdata.append("amount",amount);
    formdata.append("type", type);
    formdata.append("status", status);
    fetch(api_url + "updateinvestmentstatus", {
      method: "POST",
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
            toast.error("Status update successfully");
            fetchData();
        } else {
          toast.error("Insert failed");
        }
      })
      .catch((error) => {
        console.error("Insert error:", error);
      });
};
  const fetchData = async () => {
    try {
      const response = await fetch(api_url + `investmentlist/${user.roleid}/${user.id}`, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setmyinvestment(data); 
    console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <div
        classNameName="d-flex flex-column flex-root app-root"
        id="kt_app_root"
      >
        <div
          classNameName="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Header />
          <div
            classNameName="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            <SidebarMenu />
            <div
              classNameName="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div classNameName="d-flex flex-column flex-column-fluid">
                <div
                  id="kt_app_toolbar"
                  classNameName="app-toolbar py-3 py-lg-6"
                >
                  <div
                    id="kt_app_toolbar_container"
                    classNameName="app-container container-fluid d-flex flex-stack"
                  >
                   
                   							
                  </div>
                </div>
                <div
                  id="kt_app_content"
                  classNameName="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    classNameName="app-container container-fluid"
                  >
                    <div
                      className="row gy-5 g-xl-10"
                      data-select2-id="select2-data-126-jy4v"
                    >
                      <div className="col-xl-3 mb-xl-10">
                        
                      </div>

                      <div
                        className="col-xl-8  mb-xl-10"
                        data-select2-id="select2-data-125-pj7l"
                      >
                        <div
                          className="card card-flush h-xl-100"
                          data-select2-id="select2-data-124-kptd"
                        >
                          <div
                            className="card-header pt-7"
                            data-select2-id="select2-data-123-37nk"
                          >
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Investment List
                              </span>
                            </h3>

                            <div className="card-toolbar">
                              <div className="d-flex flex-stack flex-wrap gap-4">
                                <div className="position-relative my-1">
                                  <i className="ki-duotone ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <input
                                    type="text"
                                    data-kt-table-widget-4="search"
                                    className="form-control w-150px fs-7 ps-12"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body pt-2">
                            <div
                              id="kt_table_widget_4_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_4_table"
                                >
                                  <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        Amount
                                      </th>
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        Status
                                      </th>
                                      <th
                                        className="min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        UTR
                                      </th>
                                      <th
                                        className="text-end min-w-100px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "100px", }}
                                      >
                                        Type
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        User Name
                                      </th>
                                      <th
                                        className="text-end min-w-125px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        style={{ width: "125px" ,}}
                                      >
                                        Date
                                      </th>
                                      {
                                        user.roleid == 1&&(
                                            <th
                                            className="text-end min-w-125px sorting_disabled"
                                            rowspan="1"
                                            colspan="1"
                                            style={{ width: "125px" ,}}
                                          >
                                            Action
                                          </th>
                                        )
                                      }
                                    
                                    </tr>
                                  </thead>

                                  <tbody className="fw-bold text-gray-600">
                                  {myinvestment.map((item, index) => (
                                    <tr className="odd" key={index}>
                                      <td>
                                       
                                         {item.amount}
                                      
                                      </td>
                                      <td>
    {item.status === 0 ? (
        // If status is 0, show "pending" with pending color
        <span style={{ color: 'orange' }}>Pending</span>
    ) : item.status === 1 ? (
        // If status is 1, show "approve" with approved color
        <span style={{ color: 'green' }}>Approved</span>
    ) : (
        // If status is not 0 or 1, show "rejected" with rejected color
        <span style={{ color: 'red' }}>Rejected</span>
    )}
</td>
                                  
                                      <td >
                                         {item.utr}
                                      </td>
                                      <td className="text-end">
                                       
                                      {item.type === 1 ? (
        "investment"
    ) : (
        "withdrawal"
    )}
                                    
                                    </td>
                                    <td className="text-end">
                                       
                                    {item.name}
                                    
                                    </td>
                                    <td className="text-end">
                                       
                                       {convertDateFormat(item.mindate)}
                                       
                                       </td>
                                      {user.roleid == 1 &&(
                                        <td className="text-end">

                                  {item.status === 1 ? (
                <span style={{ color: 'green' }}>Approved</span>
            ) : (
                <button
                    onClick={() => handleClickStatusupdate(item.invesmentid, item.amount, item.type, 1)} // Pass parameters
                    type="button"
                    className="btn btn-primary" // Add Bootstrap class for button styling
                    style={{
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '9px',
                        fontSize: '11px'
                    }} // Inline styles for background color, text color, padding, and font size
                     // Disable the button while API call is in progress
                >
                    Approve
                </button>
            )}
                                      </td>
                                      )}
                                      
                                    </tr>
                                  ))}  
                                  </tbody>
                                
                                </table>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-1 mb-xl-10">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Myinvestment;
